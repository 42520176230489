import React, { useState } from "react";
import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';



import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function App() {

  return (
    <>
        <ToastContainer style={{ fontSize: 20 }} />
          <Router>
            {/* <Navbar/> */}
            <Switch>
              <Route exact path='/' component={Landing} />

       
            </Switch>
         
          </Router>
    </>
  );
}

export default App;
