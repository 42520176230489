// import logo from './logo.svg';
import '../../App.scss';

import React, { useState, useEffect } from "react";

import Roadmap from './roadmap/Roadmap';



function Landing() {



  return (
    <>
 
      <Roadmap />

    </>
  );
}

export default Landing;