import { useEffect } from 'react';
import './roadmap.scss';


export const Roadmap = () => {

  return (
    <>
      <section className="roadmaps  roadmapssecmobimg p-0" id='roadmap'>
          <div className='maindivcomg '>
          <img
                  src="\DNS-logosec.svg"
                  alt="img"
                  className="img-fluid  mobileviewneww"
                />
                <h1 className='comingtext'>
                Coming soon
                </h1>
          </div>
        {/* <img src='/commingsoon.png'className='img-fluid w-100 h-100' style={{objectFit: "cover"}}>
        </img> */}
      </section>
    </>
  );
};
export default Roadmap;
